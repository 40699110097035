<template>
  <MobileLayoutsGame>
    <template #main>
      <div id="#sports" class="sports">
        <div class="sports__top">
          <div
            v-for="(item, index) in listSportMbTop"
            :key="index"
            class="sports-item"
            @click.prevent="openSports(item)"
          >
            <BaseImg :src="item.src" alt="image-sports" />
            <h3 :class="item.class">{{ item.text }}</h3>
          </div>
        </div>
        <div class="sports__bottom">
          <div
            v-for="(item, index) in listSportMbBottom"
            :key="index"
            class="sports-item"
            @click.prevent="openSports(item)"
          >
            <BaseImg :src="item.src" alt="image-sports" />
            <h3>{{ item.text }}</h3>
          </div>
        </div>
      </div>
    </template>
  </MobileLayoutsGame>
</template>
<script setup lang="ts">
import { useSportNavigation } from '~~/composables/the-thao/useNavigateSport'
import MobileLayoutsGame from '~/components/mobile/layouts/game/index.vue'
import { listSportMbTop, listSportMbBottom } from '~/resources/sport'

const { getUrlNccSport } = useSportNavigation()
const router = useRouter()

const openSports = async (item: any) => {
  if (item.class === 'schedule') {
    router.push(item.route)
    return
  }
  try {
    const openNewTab = window.open('about:blank', '_blank') as Window
    const { data: response } = await getUrlNccSport('', item.route)
    if (response && response.status === 'OK') {
      openNewTab.location.href = response.data?.url
    } else {
      openNewTab.close()
    }
  } catch (error) {
    console.log(error)
  }
}
</script>

<style scoped lang="scss" src="~/assets/scss/components/mobile/pages/the-thao/index.scss"></style>
