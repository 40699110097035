import { IBannerList, IBannerListSport } from '~/types/sport'
import { SPORTS_URLS } from '~/config/page-url'
import { NCC_TYPES } from '~/constants/the-thao/schedule'

export const listSportTop: IBannerListSport[] = [
  {
    src: '/assets/images/components/desktop/pages/home/sport/a-sports.webp',
    alt: 'A-SPORTS',
    route: SPORTS_URLS.A_SPORTS,
    text: 'A-SPORTS',
    class: 'ksport'
  },
  {
    src: '/assets/images/components/desktop/pages/home/sport/k-sports.webp',
    alt: 'K-SPORTS',
    route: SPORTS_URLS.K_SPORTS,
    text: 'K-SPORTS',
    class: 'csport'
  },
  {
    src: '/assets/images/components/desktop/pages/home/sport/p-sports.webp',
    alt: 'P-SPORTS',
    route: SPORTS_URLS.P_SPORTS,
    text: 'P-SPORTS',
    class: 'btisport'
  }
]

export const listSportBottom: IBannerList[] = [
  {
    src: '/assets/images/components/desktop/pages/home/sport/esport.webp',
    alt: 'E SPORTS',
    route: SPORTS_URLS.E_SPORTS,
    text: 'E-Sports',
    loginRequired: true
  },
  {
    src: '/assets/images/components/desktop/pages/home/sport/betradar.webp',
    alt: 'BETRADAR',
    route: SPORTS_URLS.T_SPORTS,
    text: 'Volta 3x3',
    loginRequired: true
  },
  {
    src: '/assets/images/components/desktop/pages/home/sport/virtualsport.webp',
    alt: 'VIRTUAL',
    route: SPORTS_URLS.VIRTUAL_SPORTS,
    text: 'thể thao ảo',
    loginRequired: true
  },
  {
    src: '/assets/images/components/desktop/pages/home/sport/fixtures.webp',
    alt: 'schedules',
    route: '/the-thao/lich-thi-dau',
    text: 'lịch thi đấu',
    loginRequired: false
  }
]

export const listSportMbTop: IBannerListSport[] = [
  {
    src: '/assets/images/components/mobile/pages/the-thao/a-sport_mb.webp',
    alt: 'A-SPORTS',
    route: NCC_TYPES.SSPORTS,
    text: 'A-SPORTS',
    class: 'asport'
  },
  {
    src: '/assets/images/components/mobile/pages/the-thao/k-sport_mb.webp',
    alt: 'K-SPORTS',
    route: NCC_TYPES.KSPORTS,
    text: 'K-SPORTS',
    class: 'ksport'
  },
  {
    src: '/assets/images/components/mobile/pages/the-thao/p-sport_mb.webp',
    alt: 'P-SPORTS',
    route: NCC_TYPES.PSPORTS,
    text: 'P-SPORTS',
    class: 'psport'
  }
]

export const listSportMbBottom: IBannerListSport[] = [
  {
    src: '/assets/images/components/mobile/pages/the-thao/esport_mb.webp',
    alt: 'E SPORTS',
    route: NCC_TYPES.ESPORTS,
    text: 'E-Sports',
    class: 'esport'
  },
  {
    src: '/assets/images/components/mobile/pages/the-thao/betradar_mb.webp',
    alt: 'BETRADAR',
    route: NCC_TYPES.TSPORTS,
    text: 'Volta 3x3',
    class: 'votal'
  },
  {
    src: '/assets/images/components/mobile/pages/the-thao/virtualsport_mb.webp',
    alt: 'VIRTUAL',
    route: NCC_TYPES.VIRTUAL_SPORTS,
    text: 'thể thao ảo',
    class: 'virtualsport'
  },
  {
    src: '/assets/images/components/mobile/pages/the-thao/schedule_mb.webp',
    alt: 'schedules',
    route: '/the-thao/lich-thi-dau',
    text: 'lịch thi đấu',
    class: 'schedule'
  }
]
